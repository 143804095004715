<template>
    <div>

        <div class="tellerbox" @mouseover="hover = true"

             @mouseout="hover = false"
             :class="{'hovering':  hover, 'justify-content-start': index===0, 'justify-content-end': index ===1, 'justify-content-center': index > 1}">


            <div>

                <img class="teller" :src="require('../../assets/img/Teller_'+color+'.svg')" alt="">
                <div class="icon" v-if="check.acf.icon">

                    <img :src="check.acf.icon.url" alt="">
                </div>
                <div class="icon" v-else>

                    <img :src="require('../../assets/img/Icon_'+bigslug +'-Check.svg')" alt="">
                </div>
                <h4 :class="color">{{check.title.rendered}}</h4>
            </div>

        </div>

    </div>
</template>

<script>
    import store from '../../store'

    export default {
        store,
        name: 'Teller',
        data() {
            return {
                hover: false
            }
        },
        created: function () {

        },
        methods: {},
        props: ['color', 'check', 'index'],
        computed: {
            bigslug() {
                return `${this.check.slug[0].toUpperCase()}${this.check.slug.slice(1)}`
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" type="text/scss" scoped>
    h4 {
        &.gruen {
            color: $gruen;
        }

        &.orange {
            color: $orange;
        }

        &.violett {
            color: $violett;
        }

        &.blau {
            color: $blau;
        }

        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;

    }

    .teller {
        display: block;
        width: 100%;
        border-radius: 50%;
        box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.1);

    }

    .tellerbox {
        display: flex;

        cursor: pointer;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 30px 0px;
        @include mobile {
            justify-content: center !important;
        }

        & > div {
            position: relative;
            max-width: 190px;
            height: 190px;
            /*margin: auto;*/

        }

        &.hovering {
            .icon {
                transform: scale(1.1);
            }
        }
    }

    .tellerbox {
    }

    .icon {
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

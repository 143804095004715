<template>
  <div id="app">


    <header id="nav" class="justify-content-center " style="display: flex">
      <div class="row ">

        <div class="col-md-25 col-60 d-flex top justify-content-between">
          <router-link to="/" tag="div" style="cursor: pointer">

            <img class='logo' :src="require('@/assets/img/Logo_InForm_Mobilversion.svg')" alt="" style="">
          </router-link>
          <div style="width: 25%;" class="d-flex">
            <div class="mainswitch">

              <Menubutton :activemenu.sync="mactive" @click="mactive=!mactive"></Menubutton>
            </div>
          </div>


          <!--          </a>-->
        </div>

        <div class="col-md-35 col-60 navs" :class="{'openmenu':mactive}">
          <div class="d-flex justify-content-center">

            <h2 style="">Die Toolbox für mehr Qualität in der Kita- &
              Schulverpflegung</h2>
          </div>

          <div class="mainmenu d-md-flex ">


            <router-link to="/verpflegungsausschuss" :class="{'blau':view==='verpflegungsausschuss' }">
              Verpflegungs<br>Ausschuss
            </router-link>

            <router-link to="/speisenplanchecks" :class="{'gruen':view==='speisenplaene'}">Speisenplan<br>Checks
            </router-link>
            <router-link to="/befragungen" :class="{'violett':view==='befragungen'}">Befragungen
            </router-link>
            <router-link to="/messungen" :class="{'orange':view==='messungen'}">Messungen
            </router-link>
          </div>
        </div>

      </div>
    </header>

    <router-view/>
    <transition v-on:appear="appear">

      <footer class="justify-content-center ">
        <div class=" d-flex justify-content-center ">

          <div class="row" style="align-items: center">
            <div class="col-md-20 col-30 bmel">
              <a href="https://www.bmel.de" target="_blank">

                <img :src="require('@/assets/img/Logo_Bundeministerium_BMEL.svg')" alt="" class=""
                >
              </a>
            </div>
            <div class="col-md-40 col-60 hrsg">
              Herausgeber:
              <div style="display: flex;justify-content: space-between">
                <a href="https://www.slfg.de/" style="width: 40%;display: flex;align-items: center" target="_blank">

                  <img :src="require('@/assets/img/Logo_SLfG.svg')" alt="" style="width: 100%;">
                </a>
                <a href="https://www.schulverpflegung-thueringen.de/"
                   style="width: 40%;display: flex;align-items: center" target="_blank">

                  <img :src="require('@/assets/img/Logo_Verbraucherzentrale_Thueringen.svg')" alt=""
                       style="width: 100%;"
                  >
                </a>
              </div>

              Alle Veröffentlichungen auf dieser Website wurden im Rahmen eines Kooperationsprojekts der
              Vernetzungsstelle Kita- und Schulverpflegung Sachsen und der Vernetzungsstelle
              Schulverpflegung Thüringen erstellt und herausgegeben.


            </div>
          </div>
        </div>
        <div class="row impr">
          <div class="col-60" style="display: flex;justify-content: flex-end;">
            <router-link to="/impressum" style="margin: 10px;">Impressum</router-link>
            <router-link to="/datenschutz" style="margin: 10px;">Datenschutz</router-link>
            <a target="_blank" href="https://www.in-form.de/" style="margin: 10px;">www.in-form.de</a>
          </div>
        </div>


      </footer>
    </transition>
    <Feedbackbox v-on:close="closeBox" v-if="view!=='feedback'" :class="{'visible':boxopen}"
                 class="feedbackbox"></Feedbackbox>
  </div>

</template>

<script>
import store from './store/'

export default {
  name: 'App',
  data() {
    return {
      scolor: '',
      sopacity: '',
      mactive: false,
      boxopen: false,

    }
  },
  created: function () {
    store.dispatch('getMessungen')
    store.dispatch('getSpeiseplaene')
    store.dispatch('getBefragungen')
    store.dispatch('getVausschuss')
    store.commit('switchColor', 'orange');
    this.switchOpacity('full');
    setTimeout(function () {
      this.boxopen = true
    }.bind(this), 10000)
  },
  computed: {
    bgcolor: () => store.state.bgcolor,
    view() {
      return this.$route.meta.view;
    },
  },
  components: {
    Menubutton: () => import('@/components/parts/Menubutton'),
    Feedbackbox: () => import('@/components/parts/Feedbackbox')

  },
  methods: {
    closeBox() {
      this.boxopen = false
    },
    switchColor(col) {
      this.scolor = col;
    },

    switchOpacity(val) {
      this.sopacity = val;
    },
    appear: function (el) {
      el.velocity({

        opacity: [1, 0]
      }, {
        duration: 800,
        delay: 800
      })
    },
  },
  props: ['color', 'opac']
}
</script>

<style lang="scss">
@import './assets/custom.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('fonts/open-sans-v17-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/open-sans-v17-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('fonts/open-sans-v17-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/open-sans-v17-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.whitebox {
  background-color: #fff;
  border-radius: 20px;
  //padding: 32px;
  box-shadow: 10px 10px 10px 3px rgba(0, 0, 0, 0.1);
}

.mainmenu {
  display: flex;

  @include mobile {
    flex-wrap: wrap;

  }

  a {
    &:last-child {
      border: none;
    }

    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #aaa;
    @include mobile {
      width: 100%;
      border-right: none;
      br {
        display: none;
      }
    }

  }
}

.mainswitch {

  width: 100%;
  height: 50px;
  z-index: 10001;
  display: none;
  justify-content: flex-end;
  align-items: center;
  @include mobile {
    display: inline-flex;
  }
}

.hrsg {
  @include mobile {
    order: 1;
    font-size: 12px;
    padding: 20px;
    & > div {
      flex-wrap: wrap;
    }
    img {
      width: 80% !important;
    }
  }
}

.bmel {
  @include mobile {
    order: 2;
  }

  img {

    width: 70%;
    @include mobile {
      width: 100%;
    }
  }
}

a:hover {
  text-decoration: none;
}

img {
  max-height: 100%;
}

.logo {
  height: 90px;
  @include mobile {
    height: auto;
  }
}

.navs {
  justify-content: center;
  @include mobile {
    flex-wrap: wrap;
    display: none;
    &.openmenu {
      display: flex;
    }
  }


  h2 {
    margin-top: 10px;
    text-align: left;
    @include mobile {
      text-align: center;
      font-size: 18px;
      width: 300px;
    }
  }
}


.row {
  max-width: 1000px;

}

footer {
  font-size: 14px;
  text-align: left;
  font-weight: 700;
  opacity: 0;
}

#nav {
  padding: 5px 30px;
  margin: 0 auto;
  position: relative;
  @include mobile {
    padding: 5px;
  }
  line-height: 1.2em;
  color: #aaaaaa;
  font-size: 14px;

  .logo {
    /*width: 70%;*/
    @include mobile {
      width: 90%;
    }
  }

  @include mobile {
    .top {
      /*order: 2;*/

      a {
        /*padding-left: 10%;*/
        width: 80%;

      }
    }
  }

  a {
    text-transform: uppercase;
    color: #aaaaaa;
    padding: 5px;
    @include mobile {
      padding: 10px 0;
      margin-bottom: 5px;
      font-size: 16px;
    }
    border-bottom: 2px solid transparent;

    &.router-link-exact-active {
      color: $orange;
      border-bottom: 2px solid $orange;


    }

    &.orange {
      border-bottom: 2px solid $orange;
      color: $orange;
    }

    &.gruen {
      border-bottom: 2px solid $gruen;
      color: $gruen;
    }

    &.blau {
      border-bottom: 2px solid $blau;
      color: $blau;
    }

    &.violett {
      border-bottom: 2px solid $violett;
      color: $violett;
    }

  }

}

h2 {
  font-size: 18px;
  color: $orange;
  font-weight: 700;
}

.impr {
  margin: 0 auto;
  @include mobile {
    background: #4e555b;
    a {

      color: #fff;
    }
  }
}

button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.02em;

  &.orange {

    color: #fff;
    background: #E88312;
  }

  /* IN FORM - orange */
  background-color: #fff;
  /* IN FORM - orange */
  color: $orange;
  border: 1px solid #E88312;
  box-sizing: border-box;
  border-radius: 20px;

}

.feedbackbox {
  transform: translateX(120%);
  transition: transform 1s ease;
  opacity: 0;
  &.visible {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>

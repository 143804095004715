<template>
    <div>
        <div class="row messungen" style="justify-content: center;" v-if="level===1">
            <div v-for="(m,index) in menus" :key="m.id" class="col-md-16 col-60" :style="{'order':index}"
                 v-on:click="goTo(m.slug)">
                <!--                <div  v-if="index===1"></div>-->

                <Teller :color="bgcolor" :check="m" :index="index"></Teller>

            </div>

            <div class="col-md-28 col-60  tablet" style="padding-top: 20px;"
                 :style="{'order':0}">

                <img :src="require('@/assets/img/Tablett_Speisenplan-Checks_Schatten.png')" alt="">

            </div>
            <div class="col-md-28 col-60  tablet" :style="{'order':3}" style="padding-top: 20px;"
            >

                <img :src="require('@/assets/img/Tablett_Fruehstueck_Schatten.png')" alt="">

            </div>
            <div class="col-md-28 col-60  tablet" :style="{'order':4}" style="padding-top: 20px;"
            >
                <img :src="require('@/assets/img/Tablett_Speisenplan_rechts_Schatten.png')" alt="">


            </div>


        </div>


        <Menu :activeCheck="activeCheck" v-if="level===2"></Menu>


        <transition v-on:enter="enterMessung">

            <Messung v-for="m in menus" :key="m.slug" :m="m" v-if="m.slug===speiseplan">

            </Messung>
        </transition>


    </div>


</template>
<script>
    import store from '../store'
    import Teller from '@/components/parts/Teller.vue'
    import Messung from '@/components/Messung.vue'
    import Menu from '@/components/parts/Menu.vue'

    export default {
        name: 'About',
        data() {
            return {

                sopacity: 'half'
            }
        },
        created: function () {

            store.commit('switchColor', 'gruen');
            // this.switchOpacity('half');

        },
        components: {
            Teller, Messung, Menu
        },
        computed: {
            bgcolor() {
                return this.$route.meta.bgcolor
            },
            speiseplan() {
                return this.$route.params.slug;
            },
            menus: () => store.state.menus,
            level() {
                return this.$route.meta.level;
            },
            activeCheck() {

                return store.state.menus.filter(function (c) {
                    return c.slug === this.$route.params.slug;
                }, this)[0];
            },

        },
        methods: {
            goTo(slug) {
                this.$router.push({name: "speiseplan", params: {slug: slug}})
            }
        },
        props: ['color', 'opac']
    }
</script>
<style lang="scss" scoped>
    .messungen img {

        align-items: center;
    }

</style>
<template>
    <div class="circleicon rounded-circle" :class="[color,{active:active}]" :title="name">
        <img :src="getImg()" alt="" >
<!--        <img  :src="require('../../assets/img/Icon_'+bigslug +'-Check.svg')" alt="" >-->
    </div>
</template>

<script>
    import store from "../../store";

    export default {
        name: "Icon",
        props: ['m','color','active','name'],
        computed: {
            bigslug() {
                return `${this.m.slug[0].toUpperCase()}${this.m.slug.slice(1)}`
            },


        },

        methods: {
            getImg() {

                if (this.active) {
                  if (this.m.acf.icon_active) {

                    return this.m.acf.icon_active.url
                  }
                  else {
                    return require('@/assets/img/active/Icon_' + this.bigslug +'-Check.svg')
                  }
                }
                else {
                  if (this.m.acf.icon) {

                    return this.m.acf.icon.url
                  }
                  else {
                    return require('@/assets/img/Icon_' + this.bigslug +'-Check.svg')
                  }

                  // return require('@/assets/img/Icon_' + this.bigslug +'-Check.svg')
                }
            }
        }
    }
</script>

<style scoped lang="scss" type="text/scss">
    .circleicon {
        margin: 5px;
        height: 40px;
        cursor: pointer;
        width: 40px;
        padding: 3px;
        border: 1px solid rgba(black,0.2);
        /**/

        transition: all 1s ease;
        opacity: 1;
        &.gruen {
            border-color: $gruen;
        }
        &.orange {
            border-color: $orange;

        }
      &.violett {
            border-color: $violett;

        }
        &.blau {
            border-color: $blau;
        }
        &.active {
            //border-width: 2px;
            //opacity: 1;
            //transform: scale(1.1);
          border-color: #fff;
          &.gruen {
            background-color: $gruen;
          }
          &.orange {
            background-color: $orange;

          }
          &.violett {
            background-color: $violett;

          }
          &.blau {
            background-color: $blau;
          }
        }
        img {

        }
    }
</style>
<template>
    <div class="col-60">

    <div class="row justify-content-center boxwrap" style="">




            <div class=" col-60 intro" style="display: flex;max-width: 940px;padding-top: 10px;width: 100%;text-align: left;padding-left: 40px;font-weight: 600">

                        {{m.acf.text}}
                    
            </div>

            <div class="justify-content-center col-60" style="display: flex; max-width: 960px;">
                <div class="row justify-content-between imgwrap" >

                    <div class="col-md-34 col-60">

                        <div class="whitebox">
                            <div class="img squared" style="height: 500px;overflow: hidden" v-squared="1.33" >

                            <Fullvideo v-if="m.acf.video" :b="m.acf"></Fullvideo>
                                <img :src="m.acf.bild.sizes.large" alt="" v-if="m.acf.bild && !m.acf.video">
                            </div>
                            <div class="text" :class="bgcolor">
                            <span v-html="m.acf.beschreibung">

                            </span>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-24 col-60">
                        <div class="whitebox content" style="margin-right: 15px;">
                            <div  v-if="m.acf.dokumente">

                                <div class="dokument" v-for="(d,index) in m.acf.dokumente" :key="index"
                                     :class="bgcolor">
                                    <a :href="d.dokument.url" target="_blank">
                                        <div class="row">
                                            <div class="col-15" style="display: flex;align-items: center;justify-content: center">
                                                <img :src="require('../assets/img/'+getFileExtension(d.dokument.filename)+'.png')"
                                                     alt="" style="width: 50%;">

                                            </div>
                                            <div class="col-45" style="text-align: left;">

                                                <!--                                {{ d.dokument.subtype}}-->
                                                <div style="font-weight: 700;" v-html="d.text">

                                                    {{d.text}} <br>
                                                </div>

                                                als
                                                {{getFileExtension(d.dokument.filename)}}
                                                <!--                                        {{d.bild}}-->
                                            </div>
                                        </div>
                                    </a>

                                    <!--                                {{d}}-->
                                </div>
                            </div>

                        </div>
                        <div class="pics row d-none d-md-flex">
                            <div class="pic col-20" v-for="(d,index) in m.acf.dokumente" :key="index">

                                <img :src="d.bild.sizes.large" alt="" style="display: block;max-width: 100%;"
                                     v-if="d.bild">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


</template>

<script>
    import store from '../store'
    // import CheckIcon from './parts/Icon.vue'
    // import CircleIcon from './parts/CircleIcon.vue'
    // import Velocity from "velocity-animate";
    import Fullvideo from '@/components/parts/Fullvideo.vue'
    export default {
        store,
        name: 'Messung',
        data() {
            return {}
        },
        components: {Fullvideo},
        created: function () {
        },
        methods: {
            goTo(slug) {
                if (this.$route.meta.view === 'messungen') {
                    this.$router.push({name: "messung", params: {messung: slug}})
                } else {
                    this.$router.push({name: "speiseplan", params: {speiseplan: slug}})
                }
            },
            getFileExtension(filename) {
                return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
            },
        },
        computed: {
            checks() {

                return this.$route.meta.view === 'messungen' ? store.state.messungen : store.state.menus;
            },
            bgcolor () {
                return this.$route.meta.bgcolor
            },
        },
        props: ['m']
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" type="text/scss">
    .intro {
        @include mobile {
            font-size: 14px;
        }
    }
    .imgwrap {
        @include mobile {
            justify-content: center!important;

        }
    }.boxwrap {
        @include mobile {
            margin-left: 0;
            margin-right: 0;

        }
    }
    .dokument {
        padding: 20px 10px;
        font-size: 14px;

        &:first-child {
            border: none !important;
        }

        &.orange {
            border-top: 1px solid $orange;
        }

        &.gruen {
            border-top: 1px solid $gruen;
        }
    }

    .pic {
        padding: 5px;
    }

    a {
        color: inherit;
    }

    .whitebox {
        transition: transform 0.6s cubic-bezier(.78,2,1, 1) ;
        background-color: #fff;
        margin: 20px 0;
        border-radius: 20px;
        box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.1);
        & > div {
            min-height: 10px;
        }

        .img {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;

            img {
                display: block;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                width: 100%;
            }

        }

        .text {
            padding: 20px 25px;
            font-size: 14px;

        }
    }

    .text {
        &.orange {
            border-top: 1px solid $orange;
        }

        &.gruen {

            border-top: 1px solid $gruen;
        }
    }
</style>

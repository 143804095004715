import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        messungen: [],
        menus: [],
        befragungen: [],
        vausschuss: [],
        bgcolor: 'orange',
        bgopacity: 'full',
        pages: [],
        surveys: []
    },
    mutations: {
        saveMessungen(state, data) {
            state.messungen = data
        },
        saveSpeiseplaene(state, data) {
            state.menus = data
        },
        saveBefragungen(state, data) {
            state.befragungen = data
        },
        saveVausschuss(state, data) {
            state.vausschuss = data
        },
        switchColor(state, col) {
            state.bgcolor = col;
        },
        switchOpacity(state, val) {
            state.bgopacity = val;
        },
        savePages(state,data) {
            state.pages = data;
        },
        saveSurveys(state,data) {
            state.surveys = data;
        },
    },
    actions: {
        getMessungen(store) {
            Axios.get('/wp-json/wp/v2/messungen?_embed&orderby=menu_order&order=asc').then(function (response) {
                // window.sessionStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                store.commit('saveMessungen', response.data);
            });
        },
        getSpeiseplaene(store) {
            Axios.get('/wp-json/wp/v2/checks?_embed&orderby=menu_order&order=asc').then(function (response) {
                // window.sessionStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                store.commit('saveSpeiseplaene', response.data);
            });
        },
        getBefragungen(store) {
            Axios.get('/wp-json/wp/v2/befragungen?_embed&orderby=menu_order&order=asc').then(function (response) {
                // window.sessionStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                store.commit('saveBefragungen', response.data);
            });
        },
        getVausschuss(store) {
            Axios.get('/wp-json/wp/v2/ausschuss?_embed&orderby=menu_order&order=asc').then(function (response) {
                // window.sessionStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                store.commit('saveVausschuss', response.data);
            });
        },
        getSurveys(store) {
            Axios.get('/wp-json/wp/v2/surveys?_embed&orderby=menu_order&order=asc').then(function (response) {
                // window.sessionStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                store.commit('saveSurveys', response.data);
            });
        },
        getPages(store) {
            Axios.get('/wp-json/wp/v2/pages?_embed&orderby=menu_order&order=asc').then(function (response) {
                // window.sessionStorage.setItem("CACHE_$" + response.url, JSON.stringify(response.data));
                store.commit('savePages', response.data);

            });
        },
    },

    modules: {}
})

<template>
    <div class="icon" >
        <img :src="getImg()" alt="" v-if="m">
    </div>
</template>

<script>
    export default {
        name: "Icon",
        props: ['m'],
        computed: {
            bigslug() {
                return `${this.m.slug[0].toUpperCase()}${this.m.slug.slice(1)}`
            }
        },
        methods: {
            getImg() {
                if (this.m.acf.icon) {
                    return this.m.acf.icon.url
                }
                else      return require('@/assets/img/Icon_' + this.bigslug +'-Check.svg')
            }
        }


    }
</script>

<style scoped lang="scss" type="text/scss">
.icon {
    max-height: 50px;

    img {
       height: 50px;
    }
}
</style>
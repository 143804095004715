<template>
    <div>
        <div class="col-60 page">

<!--            <div class="row page" style="padding-top: 50px;">-->
<!--                <div class="col-60 ">-->
                    <div class="whitebox">

                    <h1 class="title" v-html="page.title.rendered" v-if="page">{{}}</h1>
                        <h1 v-else>Seite nicht gefunden</h1>
                    <div v-html="page.content.rendered" v-if="page">{{}}</div>
                    </div>
<!--                </div>-->
<!--            </div>-->
        </div>
        <!--        <div class="row">-->
        <!--            <div class="col">Home</div>-->
        <!--            <div class="col">Home</div>-->
        <!--        </div>-->
    </div>
</template>

<script>
    import store from '@/store'

    export default {
        store,
        name: 'Page',
        props: {page: Object},
        data() {
            return {}
        },
        created: function () {

        },
        methods: {}
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" type="text/scss" scoped>

</style>

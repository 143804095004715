<template>
  <div class="home">


    <div class='bg'
         :class="'bg-'+bgcolor + '-'+sopacity"
         :style="{backgroundImage:getBackground(require('../assets/img/Hintergrund_Linien_'+bgcolor+'_'+sopacity+'.svg'))}">
      <div style="min-height: 650px;display: flex;justify-content: center;position: relative">

        <transition v-on:enter="enter">
          <Home v-if="view==='home'" class="move row"></Home>
          <Page v-if="view==='page'" class="move row" :page="page"></Page>
          <Messungen v-if="view==='messungen'" class="move row" :class="{'absolute':level<2}"></Messungen>
          <Speiseplaene v-if="view==='speisenplaene'" class="move row"
                        :class="{'absolute':level<2}"></Speiseplaene>
          <Befragungen v-if="view==='befragungen'" class="move row"
                       :class="{'absolute':level<2}"></Befragungen>
          <VAusschuss v-if="view==='verpflegungsausschuss'" class="move row"
                      :class="{'absolute':level<2}"></VAusschuss>
          <Feedback v-if="view==='feedback'" ></Feedback>
        </transition>
      </div>
      <transition v-on:appear="footAppear">

        <div class="justify-content-center d-none d-md-flex " style="transform:translateY(100%)">

          <div class="row explainer">
            <div class="col-md-20 col-60 orange">
              Die Toolbox
            </div>
            <div class="col-md-40 col-60 gruen">
              Von der Gründung eines Verpflegungsausschusses bis hin zu detaillierten Speisenplan-Checks
              finden Sie auf unserer Seite eine Vielzahl an Anleitungen und Instrumenten, mit denen Sie
              die Qualität der Kita- und Schulverpflegung überprüfen, nachhaltig verbessern und sichern
              können. Für eine gesunde Entwicklung und mehr Zufriedenheit von Kindern und Jugendlichen.
            </div>
          </div>
        </div>
      </transition>
      <div class="mobilebox justify-content-center d-flex d-md-none ">

        <div class="row explainer">
          <div class="col-md-20 col-60 orange">
            Die Toolbox
            <p>
              Von der Gründung eines Verpflegungsausschusses bis hin zu detaillierten Speisenplan-Checks
              finden Sie auf unserer Seite eine Vielzahl an Anleitungen und Instrumenten, mit denen Sie
              die Qualität der Kita- und Schulverpflegung überprüfen, nachhaltig verbessern und sichern
              können. Für eine gesunde Entwicklung und mehr Zufriedenheit von Kindern und Jugendlichen.
            </p>
          </div>
          <div class="col-md-40 col-60 gruen">
            Von der Gründung eines Verpflegungsausschusses bis hin zu detaillierten Speisenplan-Checks
            finden Sie auf unserer Seite eine Vielzahl an Anleitungen und Instrumenten, mit denen Sie die
            Qualität der Kita- und Schulverpflegung überprüfen, nachhaltig verbessern und sichern können.
            Für eine gesunde Entwicklung und mehr Zufriedenheit von Kindern und Jugendlichen.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Messungen from '@/components/Messungen.vue'
import Speiseplaene from '@/components/Speiseplaene.vue'
import Befragungen from '@/components/Befragungen.vue'
import VAusschuss from '@/components/VAusschuss.vue'
import Page from '@/components/Page.vue'
import Feedback from '@/components/Feedback.vue'
import Home from '@/components/Home.vue'
import store from "../store";
import $ from "jquery";

export default {
  name: 'core',
  components: {
    Messungen, Speiseplaene, Befragungen, VAusschuss, Page, Home, Feedback
  },
  data() {
    return {}
  },
  created: function () {
    store.dispatch('getPages')
    // store.dispatch('getSpeiseplaene')
    // store.commit('switchColor', 'orange');

  },
  mounted() {
    document.title = this.getTitle()
  },
  watch: {
    $route() {
      document.title = this.getTitle()
      // if (this.$route.meta.view === 'speiseplaene') store.commit('switchColor', 'gruen');
      //
      // else store.commit('switchColor', 'orange');

    }
  },
  computed: {
    view() {
      return this.$route.meta.view;
    },
    level() {
      return this.$route.meta.level;
    },
    messungen: () => store.state.messungen,
    menus: () => store.state.menus,
    sopacity() {
      return this.$route.meta.level > 0 || this.view==='home'  ? 'half' : 'full'
    },
    bgcolor() {
      // return store.state.bgcolor
      return this.$route.meta.bgcolor
    },
    page: function () {
      return store.state.pages.filter(p => p.slug === this.$route.params.page)[0]
    },
  },
  methods: {
    getTitle() {
      let title = '';
      if (this.view === 'page' && this.page) {
        title += this.page.slug.split('-').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ') + ' | '

      } else if (this.level >= 2) {
        let slug = this.$route.params.slug.split('-').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
        title += slug + ' | ';
      } else if (this.level >= 1) {
        title += this.$route.meta.title + ' | ';
      }



      return title + "Die Toolbox für mehr Qualität in der Kita- & Schulverpflegung";
    },
    switchColor(col) {
      this.scolor = col;
    },
    switchOpacity(val) {
      this.sopacity = val;
    },
    footAppear: function (el) {
      el.velocity({

        transform: ['translateY(0)', 'translateY(100%)']
      }, {
        duration: 400,
        delay: 400
      })
    },
    enter: function (el) {
      // el.velocity({opacity:1, transform:['translateX(1000px)','translateX: 100px']}, 600)
      el.velocity({
        opacity: [1, 0],
        transform: ['translateX(0px)', 'translateX(1000px)']
      }, {
        duration: 400,
        delay: 0
      })

      // $(el).find('.tellerbox')[0].velocity({
      //     transform: ['translateX(0px)', 'translateX(50px)']
      // })
      $('.tellerbox').each(function (index) {

        this.velocity({
          transform: ['translateX(0px)', 'translateX(' + index * 50 + 'px)']
        }, {duration: 400})
      })
      $('.icon').each(function () {

        this.velocity({
          transform: ['translateX(0px)', 'translateX(100px)']
        }, {duration: 300})
      })

    },
  },
  props: ['color', 'opac']
}
</script>

<style lang="scss">
.tellerbox {

  transition: transform 0.4s cubic-bezier(.78, 3, .7, 1);
}

.icon {
  transition: transform 0.4s cubic-bezier(.78, 3, .7, 1);
}

.move-enter {
  .tellerbox {
    /*transform: translateX(50px);*/
  }

  .icon {
    /*transform: translateX(50px);*/
  }
}

.move-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
  transform: translateX(-100%);
}

.move {

  display: flex;

  &.absolute {
    /*position: absolute;*/
    top: 0;
    width: 100%;

  }
}

.row {
  /*max-width: 100%;*/
  max-width: 1080px;
  width: 100%;
  position: relative;
}

.bg {
  background-size: cover;
  overflow: hidden;
  box-shadow: 0px 10px 5px 0 inset rgba(0, 0, 0, 0.05), 0px -10px 5px 0 inset rgba(0, 0, 0, 0.05);

}

.bg-gruen-half {
  /*background-image: url('@assets/') ;*/
  background-color: rgba(#9de269, 0.2);
}

.bg-gruen-full {
  /*background-image: url('@assets/') ;*/
  background-color: rgba(#9de269, 1);
}

.bg-orange-half {
  background-color: rgba(#f8daa8, 0.5);
}

.bg-orange-full {
  background-color: rgba(#f8daa8, 1);

}

.bg-violett-half {
  background-color: rgba(120, 54, 131, 0.2);
}

.bg-violett-full {
  background-color: rgba(120, 54, 131, 0.2);

}

.bg-blau-half {
  background-color: rgba(110, 136, 157, 0.35);
}

.bg-blau-full {
  background-color: rgba(110, 136, 157, 1);

}

.explainer {
  overflow: hidden;
  @include mobile {
    position: relative;
    padding: 10px 30px;

  }

  & > div {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }

  .orange {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: rgba($orange, 0.9);
    padding: 20px 20px 40px 20px;
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    z-index: 2;
    box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.1);
    @include mobile {
      padding: 20px 30px 40px 20px;
      transform: translate3d(-10px, 10px, 0);
      p {
        font-size: 14px;
        text-align: left;
        width: 100%;
        opacity: 0;
      }
    }
  }

  .gruen {
    font-weight: 600;
    z-index: 1;
    font-size: 14px;
    background-color: rgba($gruen, 0.8);
    transform: translate3d(0, 20px, 0) scale(1.05);
    transform-origin: right;
    /*transform: scale(1.1);*/
    text-align: left;
    box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.1);
    padding: 20px 50px 40px 60px;
    @include mobile {
      transform: translate3d(-30px, 0, 0) scale(0.9);
      transform-origin: right bottom;
      z-index: 3;
      position: absolute;
      bottom: 0;

    }
  }
}
</style>

<template>
  <div class="move row">
    <form style="width: 100%;" v-on:submit.prevent="sendForm(data)">
      <div class="whitebox">
        <div class="d-flex justify-content-between mobilecol">
          <h4>Wie gefällt Ihnen die Toolbox?</h4>
          <div>
            <star-rating v-model="data.general.stars" :show-rating="false"
                         :inactive-color=" $v.data.general.stars.$invalid && errorsent?'red':'#fff'"
                         active-color="#DDA727" :class="{'red':$v.data.general.institution.$invalid && errorsent}"
                         border-color="#DDA727" :rounded-corners="true" :border-width="1" :padding="10"
            ></star-rating>
          </div>
        </div>
        <hr>
        Haben Sie allgemeine Anmerkungen zur Toolbox?
        <textarea style="width: 100%;" rows="5" placeholder="Ihre Antwort" v-model="data.general.notes"></textarea>
      </div>
      <div class="whitebox">
        <h4 style="margin-bottom: 20px;">Persönliche Angaben</h4>
        <div class="d-flex justify-content-between align-items-center mobilecol">
          <div>Aus welchem Bundesland kommen Sie?</div>
          <div>Ich komme aus:
            <select class="" name="" id="" v-model="data.general.state"
                    :class="{'red':$v.data.general.state.$invalid && errorsent}">
              <option value="" disabled selected class="disabled">Bundesland</option>
              <option :value="s" v-for="s in states" :key="s" v-html="s"></option>
            </select></div>
        </div>
        <hr>
        <div class="d-flex justify-content-between mobilecol">
          <div class="halfwidth">Aus welcher Position heraus gilt
            Ihr Interesse der Kita-/ Schulverpflegung?
          </div>
          <div>Ich bin:&nbsp;
            <select class="" name="" id="" v-model="data.general.position"
                    :class="{'red':$v.data.general.position.$invalid && errorsent}">
              <option value="" disabled selected class="disabled">Position</option>
              <option :value="p" v-for="p in positions" v-html="p" :key="p"></option>
            </select></div>
        </div>
        <hr>
        <div class="d-flex justify-content-between mobilecol">
          <div>In welcher Einrichtung haben Sie das Instrument angewandt?</div>
          <div>
            <select class="" name="" id="" v-model="data.general.institution"
                    :class="{'red':$v.data.general.institution.$invalid && errorsent}">
              <option value="" disabled selected class="disabled">Einrichtung</option>
              <option :value="o" v-for="o in orgs" v-html="o" :key="o"></option>
            </select></div>
        </div>
      </div>
      <div class="whitebox">
        <h4>Zu welchen Instrumenten wollen Sie Feedback geben?</h4>
        <div> Sie können mehrere Feedback-Bögen parallel ausfüllen. Vollständig ausgefüllte Bögen können Sie am
          &check; ausblenden.
        </div>
        <div class="selection d-flex">
          <div class="group">

            <CircleIcon v-for="(c) in vausschuss" :key="c.id" :m="c" :name="c.title.rendered"
                        :active="isActive(getSurvey(c.id))"
                        @click.native="toggleInstrument(c)"
                        :color="'blau'"

            ></CircleIcon>
          </div>
          <div class="verticalline"></div>
          <div class="group">

            <CircleIcon v-for="(c) in menus" :key="c.id" :m="c" @click.native="toggleInstrument(c)"
                        :color="'gruen'" :active="isActive(getSurvey(c.id))" :name="c.title.rendered"
            ></CircleIcon>
          </div>
          <div class="verticalline"></div>
          <div class="group">

            <CircleIcon v-for="(c) in befragungen" :key="c.id" :m="c" @click.native="toggleInstrument(c)"
                        :color="'violett'" :active="isActive(getSurvey(c.id))" :name="c.title.rendered"
            ></CircleIcon>
          </div>
          <div class="verticalline"></div>
          <div class="group">

            <CircleIcon v-for="(c) in messungen" :key="c.id" :m="c" @click.native="toggleInstrument(c)"
                        :color="'orange'" :active="isActive(getSurvey(c.id))" :name="c.title.rendered"
            ></CircleIcon>
          </div>
        </div>
      </div>
      <Fragebogen :instrument="instrument" v-for="(instrument,index) in instruments" :key="instrument.ID"
                  :survey="getSurvey(instrument.id)" @input="updateValue"></Fragebogen>
      <div class="whitebox">
        <div class="d-flex justify-content-start">
          <div class="protection custom-control custom-switch"
               :class="{'red': $v.data.general.protection.$invalid && errorsent}">

            <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="data.general.protection">
            <label class="custom-control-label" for="customSwitch1">Datenschutzrichtlinien zustimmen</label>

          </div>


        </div>
        <div class="d-flex justify-content-start mobilecol align-items-center">
          <div class="protection custom-control custom-switch" style="margin-right: 10px;">
            Haben Sie alle genutzten Instrumente bewertet? Dann können Sie das
          </div>&nbsp;&nbsp;&nbsp;
          <button type="submit" class="orange button" v-if="!justsent">Feedback abschicken</button>

        </div>
        <hr>
        Mit dem Abschicken Ihres Feedbacks müssen Sie unseren <a href="/datenschutz" target="_blank">Datenschutzrichtlinien </a>zustimmen.
        Ihre Daten werden nicht an Dritte weitergegeben und auschließlich zum Zweck der Verbesserung unserer Angebote
        verwendet.
        <div v-if="errorsent&&$v.data.general.$invalid" class="error " style="color:red;">Bitte rot markierte Felder
          ausfüllen
          <div v-if="$v.data.general.stars.$invalid" @click="window.scrollTo({top: 0})">Vergessen Sie bitte nicht die
            generelle Bewertung!
          </div>
        </div>
      </div>
      <div class="whitebox" v-if="justsent">
        <h4 class="success ">Danke für Ihr Feedback!
        </h4>

      </div>
<!--{{selectedSurveys}}-->

    </form>
  </div>
</template>

<script>
import store from "@/store";
import jQuery from 'jquery'

import CircleIcon from '@/components/parts/CircleIcon.vue'
import Fragebogen from '@/components/parts/Fragebogen.vue'
import StarRating from 'vue-star-rating'
import {checked, required, minLength, maxLength, email} from 'vuelidate/lib/validators'

export default {
  name: "Feedback",
  components: {CircleIcon, Fragebogen, StarRating},
  data() {
    return {
      states: ['Baden-Württemberg', 'Bayern', 'Berlin', 'Brandenburg', 'Bremen', 'Hamburg', 'Hessen', 'Mecklenburg-Vorpommern', 'Niedersachsen', 'Nordrhein-Westfalen', 'Rheinland-Pfalz', 'Saarland', 'Sachsen', 'Sachsen-Anhalt', 'Schleswig-Holstein', 'Thüringen'],
      positions: ["Pädagog*in", "Elternteil", "Caterer/Küchenfachkraft", "Schüler*in", "tätig bei einem Kita- /Schulträger", "Sonstiges"],
      orgs: ['Kita', "Schule"],
      selectedSurveys: [],
      data: {
        general: {protection: false}
      },
      justsent: false,
      errorsent: false,
      window: window
    }
  },
  validations: {
    data: {
      general: {
        protection: {
          checked: value => value === true
        },
        state: {
          required
        },
        position: {
          required
        },
        institution: {
          required
        },
        stars: {required}
      }
    }
  },
  created() {
    store.dispatch('getSurveys')
  },
  methods: {
    sendForm: function (data) {
      if (this.$v.data.general.$invalid) {
        this.errorsent = true;
        return false;
      }
      data.selectedSurveys = this.selectedSurveys
      let strippeddata = JSON.parse(JSON.stringify(data))
      let url = process.env.NODE_ENV === 'development' ? 'https://toolbox-verpflegung.de/feedbackcompleted' : '/feedbackcompleted'
      console.log(data)
      jQuery.ajax({
        url: url,
        type: 'post',
        data: strippeddata,
        error: function () {
          this.errorsent = true
        }.bind(this)
      })
          .done(function (val) {
            try {
              if (JSON.parse(val).success) {
                this.justsent = true;
                document.getElementById('anfrageform').scrollIntoView();

              } else this.errorsent = true;
            } catch (e) {
              console.log(e)
              this.errorsent = true
            }
          }.bind(this))
    },
    toggleInstrument(i) {
      let ids = this.selectedSurveys.map(s => s = s.id)
      if (ids.indexOf(i.id) > -1) {
        this.selectedSurveys.map(function (s) {
              if (s.id === i.id) s.active = !s.active
            }
        )
      } else {
        this.addInstrument(i)
      }

    },
    isActive(obj) {
      return obj ? obj.active : false
    },
    addInstrument(i) {
      let survey = {
        id: i.id, name: '', instrument: this.getInstrument(i.id), answers: [], active: true, closed: false
      }
      let fragen = store.state.surveys.filter(survey => survey.id === i.acf.fragebogen.ID)[0].acf.fragen;
      fragen.forEach(function (f) {
        survey.answers.push({'frage': f.frage, 'rating': "0"})
      })

      this.selectedSurveys.push(survey)
    },
    getSurvey(id) {
      return this.selectedSurveys.filter(s => s.id === id)[0]
    },
    updateValue(result) {
      this.selectedSurveys.forEach(function (s) {
        if (s.id === result.id) {
          s[result.key] = result.value;
        }
      })
    },
    getInstrument(id) {
      let instr = store.state.vausschuss.concat(store.state.menus).concat(store.state.befragungen).concat(store.state.messungen)
      return instr.filter(i => id === i.id)[0].title.rendered
    }
  }
  ,
  computed: {
    messungen: () => store.state.messungen,
    menus: () => store.state.menus,
    vausschuss: () => store.state.vausschuss,
    befragungen: () => store.state.befragungen,
    instruments: function () {
      let instr = store.state.vausschuss.concat(store.state.menus).concat(store.state.befragungen).concat(store.state.messungen)
      // let surveyids = []
      let ids = this.selectedSurveys.map(s => s = s.id)
      return instr.filter(i => ids.indexOf(i.id) > -1)
    }
  }
}
</script>
<style lang="scss">
.vue-star-rating-rating-text {
  display: none;
}

//
//.vue-star-rating-star {
//polygon {
//  fill: #fff;
//  stroke-width: 1px;
//  stroke: $orange;
//}
//}
</style>

<style scoped lang="scss">
.halfwidth {
  max-width: 50%;
  @include mobile {
    max-width: 100%;
  }
}
.mobilecol {
  @include mobile {

  flex-direction: column;
  }
}
.selection {
  justify-content: space-between;
  margin: 20px 0;

  .verticalline {
    background-color: #b9b9b9;
    width: 1px;
  }
}

.button {
  &.send {

  }
}


.group {
  display: flex;

  div {
    margin: 0 10px;
  }
}

.whitebox {
  width: 100%;
  text-align: left;
  padding: 32px;
}

.protection {
  &.red {
    border-color: red;

    label {
      color: red;

    }
  }
}

textarea {
  border-radius: 20px;
  appearance: none;
  border: 1px solid #B9B9B9;
  margin: 8px 0px;
  padding: 8px 16px;

  &:focus {
    outline: none;
  }
}

select {
  min-width: 260px;
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;

  &.red {
    border-color: red !important;
  }

  background: url('../assets/img/chevron-black.svg') no-repeat scroll right 16px center;

  &:focus {
    background: url('../assets/img/chevron.svg') no-repeat scroll right 16px center;

    outline: none;
    border-radius: 20px 20px 0 0;
    background-color: #3D3D3D;
    color: #fff;

    option {
      background-color: #fff;
      color: #3D3D3D;

      margin: 0 8px;


    }
  }

  option {

    &.disabled {
      color: lighten(#3D3D3D, 30%);
      //color: #fff;
    }
  }

  border-radius: 20px;
  padding: 8px 16px;

  &:invalid {
    opacity: 0.8;
  }

}

hr {
  border-top: 1px solid #B9B9B9;
}
</style>
<template>
  <div>
    <div class="whitebox fragebogen" v-if="survey.active">
      <div class="head" :class="instrument.type" @click="toggleSurvey(survey)">
        <h4 class="white d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <CircleIcon :m="instrument" :active="true" style="margin-right: 20px;"></CircleIcon>
            <span v-if="instrument.acf.singleheader" v-html="instrument.acf.singleheader"></span>
            <span v-else v-html="instrument.title.rendered"></span>
          </div>
          <div v-if="survey.closed" style="margin-right: 10px;cursor: pointer">
            +
          </div>
          <div v-else style="margin-right: 10px;cursor: pointer">
            ✓
          </div>
        </h4>
      </div>
      <div class="inner" :class="{'closed':survey.closed}">
        <h4 style="margin-bottom: 20px;">Inwiefern treffen folgende Aussagen auf den Einsatz des Instruments zu?</h4>
        <div v-for="(q, index) in questions" :key="index" class=" questions">
          <div class="d-flex justify-content-between mobilecol align-items-center">
            <div class="d-flex question" style="">
              <div class="number" style="">
                {{ index + 1 }}
              </div>
              <div class="d-flex align-items-center">
                {{ q.frage }}
              </div>
            </div>
            <div class="d-flex justify-content-between answer">
              <div class="field " v-for="i in ['2','1','-1','-2','0']" :key="i"
                   :class="{last: i==='0','active': survey.answers[index].rating === i}">
                <input type="radio" :value="i" :key="i" :name="'rating_'+instrument.id+'_'+index"
                       :id="'rating_'+instrument.id+'_'+index + '_' + i" v-model="survey.answers[index].rating"
                       style="visibility: hidden">
                <label :for="'rating_'+instrument.id+'_'+index+'_'+i" style="cursor: pointer">
                  <img :src="require('@/assets/img/faces/face'+i+'.svg')" alt="">
                  <img class="active" :src="require('@/assets/img/faces/face'+i+'_active.svg')" alt="">
                </label>
              </div>
            </div>
          </div>
          <div class="extra d-flex" v-if="q.zusatz">
            <div class="text" style="width: 60%;">
              {{ q.extratext }}
            </div>
            <div style="width:40%;">
              <textarea name="" id="" style="width: 100%;margin-top: 20px;" rows="5" placeholder="Ihre Antwort"
                        v-model="survey.answers[index].extra"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import CircleIcon from '@/components/parts/CircleIcon.vue'
// import Frage from '@/components/parts/Frage.vue'

export default {
  name: "Fragebogen",
  components: {CircleIcon},
  data() {
    return {
      answers: [],
    }
  },
  created() {
  },
  validations: {
    data: {
      // accept: {
      //   checked: value => value === true
      // },
      // name: {
      //   required,
      //   minLength: minLength(3)
      // },
      // email: {
      //   required, email
      // },
      // hpmessage: {
      //   maxLength: maxLength(0)
      // }
    }
  },
  props: ['survey', 'instrument'],
  methods: {
    updateValue(key, value) {
      this.$emit("input", {'key': key, 'value': value, 'id': this.instrument.id});
    },
    toggleSurvey(s) {
      s.closed = !s.closed
    }

  },
  computed: {
    questions: function () {
      let survey = store.state.surveys.filter(survey => survey.id === this.instrument.acf.fragebogen.ID)
      if (survey.length > 0) {
        return survey[0].acf.fragen;
      } else return []
    }
  }

}
</script>

<style scoped lang="scss">
.mobilecol {
  @include mobile {
    flex-direction: column;
  }
}

.extra {
  margin-top: 20px;

  textarea {
    border-radius: 20px;
    outline: none !important;
    padding: 15px;
  }
}

.field {
  label {

    img.active {
      display: none
    }
  }

  &.active {

    label {
      font-weight: bold;
      border: 1px black;

      img {
        display: none;
      }

      img.active {
        display: inline-flex;
      }
    }
  }
}

.number {

}

.questions {
  justify-content: space-between;
  align-items: center;
  padding: 28px 0 28px 0;
  border-top: 1px #B9B9B9 solid;
  border-bottom: 1px #B9B9B9 solid;

  .question {
    width: 55%;
    @include mobile {
      width: 100%;
    }
  }
  .answer {
    width: 40%;
    @include mobile {
      width: 80%;
    }
  }

  .number {
    //styleName: <h1>;
    //font-family: Roboto;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.02em;
    text-align: center;
    min-width: 100px;
    @include mobile {
      width: auto;
      min-width: 50px;
    }
  }
}

.field.last {
  margin-left: 20px;
  padding-left: 30px;
  border-left: 1px solid #B9B9B9;
}

.whitebox.fragebogen {
  padding: 0;

  .inner {
    padding: 32px;
    text-align: left;
    min-height: 0;

    &.closed {
      height: 0;
      padding: 0;
      overflow: hidden;
    }
  }
}

h4.white {
  color: #fff;
  margin-bottom: 0;
}

.head {
  padding: 5px 10px;
  border-radius: 20px 20px 0 0;

  &.ausschuss {
    background-color: $blau;
  }

  &.checks {
    background-color: $gruen;
  }

  &.befragungen {
    background-color: $violett;
  }

  &.messungen {
    background-color: $orange;
  }


}
</style>
<template>
    <div>
        <div class="row messungen" style="justify-content: center;" v-if="level===1">
            <div v-for="(m,index) in befragungen" :key="m.id"
                 class="col-md-18 col-60 align-items-start justify-content-center"
                 :style="{'order':index}" v-on:click="goTo(m.slug)">
                <!--                <div  v-if="index===1"></div>-->
                <Teller :color="bgcolor" :check="m" :index="index"></Teller>
            </div>
            <div class="col-md-24 col-60 d-flex  justify-content-center tablet" :style="{'order':0}">
                <img :src="require('@/assets/img/Tablett_Befragungen_Schatten.png')" alt="">
<!--                <img :src="m.acf.icon.url" alt="">-->

            </div>
            <div class="col-md-28 col-60  tablet" :style="{'order':3}" style="padding-top: 20px;"
            >

                <img :src="require('@/assets/img/Grafik_Befragungen_Schatten.png')" alt="">

            </div>
        </div>

        <Menu :activeCheck="activeCheck" v-if="level===2"></Menu>

        <transition v-on:enter="enterMessung" v-on:appear="enterMessung">
            <Messung v-for="m in befragungen" :key="m.slug" :m="m" v-if="m.slug===befragung">
            </Messung>
        </transition>


    </div>


</template>
<script>
    import store from '../store'
    import Teller from './parts/Teller.vue'
    import Menu from './parts/Menu.vue'
    import Messung from './Messung.vue'

    export default {
        name: 'About',
        data() {
            return {

                sopacity: 'half'
            }
        },
        components: {
            Teller, Messung, Menu
        },
        created: function () {
            store.commit('switchColor', 'violett');
        },
        computed: {
            bgcolor () {
                return this.$route.meta.bgcolor
            },
            befragung() {
                return this.$route.params.slug;
            },
            messungen: () => store.state.messungen,
            befragungen: () => store.state.befragungen,
            menus: () => store.state.menus,
            level() {
                return this.$route.meta.level;
            },
            activeCheck() {

                return store.state.befragungen.filter(function (c) {
                    return c.slug === this.$route.params.slug;
                }, this)[0];
            }
        },
        methods: {
            getClass(i) {
                return i === 1 ? "col-42" : "col-18"
            },
            goTo(slug) {
                console.log('click')
                this.$router.push({name: "befragung", params: {slug: slug}})
            },

        },
        props: ['color', 'opac']
    }
</script>
<style lang="scss">
    .messungen {
        margin: auto;

        & > div {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    img {
        max-width: 100%;
    }

    .tablet {
        transition: transform 0.5s linear 0.2s;
        transform: rotate(0deg);
        align-items: start;

        img {
            margin: auto;
        }

        @include mobile {
            align-items: center;
        }
    }


</style>
<template>
    <div class='vwrap '>
        <link href="//vjs.zencdn.net/7.8.2/video-js.min.css" rel="stylesheet">
        <video ref="videoPlayer" class="video-js vjs-4-3" :style="{ width: '100%', marginTop: '0px'}"
               muted></video>
<!--        {{b.video}}-->
    </div>
</template>
<script>
    import videojs from 'video.js';

    export default {
        name: "Fullvideo",
        props: {
            options: {
                type: Object,
                default() {
                    return {};
                }
            },
            height: {type: String},
            b: {type: Object}
        },
        data() {
            return {
                player: null
            }
        },
        mounted() {
            this.player = videojs(this.$refs.videoPlayer, this.getOptions(this.b), function onPlayerReady() {
                console.log('onPlayerReady', this);
            })
        },
        watch: {
            b() {
                console.log('watched')
                if (this.player) {
                    console.log('reset')
                }
                this.player.reset()
                // this.player = videojs(this.$refs.videoPlayer, this.getOptions(this.b), function onPlayerReady() {
                //     console.log('onPlayerReady', this);
                // })#
                this.player.src({ type: "video/mp4", src: this.b.video.url });
                // this.player.src = (
                //     [{'src': this.b.video.url}])
                this.player.play()
                // console.log(this.player)
            }
        },
        updated() {
            console.log('updated')

            this.player = videojs(this.$refs.videoPlayer, this.getOptions(this.b), function onPlayerReady() {
                console.log('onPlayerReady', this);
            })
        },
        beforeDestroy() {
            if (this.player) {
                this.player.dispose()
            }
        },
        methods: {
            getOptions(b) {
                return {
                    autoplay: true,
                    controls: false,
                    loop: true,
                    height: 'auto',
                    poster: b.bild.sizes.large,
                    sources: [
                        {
                            src:
                            b.video.url,
                            type: "video/mp4"
                        }
                    ]
                }
            }
        }
    }
</script>
<style lang="scss" type="text/scss" scoped>

    video {
        /*height: 500px;*/

       // @include mobile {
        //    height: 320px;
        //  }
    }

    .vwrap {
        div {
            @include mobile {
         //      max-height: 320px;
          //      max-width: 320px;
            }
        }

        overflow: hidden;
        /*max-width: 100%;*/
        position: relative;
        /*top: 0;*/
        width: calc(100% + 20px);
        margin-left: -10px;
        /*height: 480px;*/
        @include mobile {
         //   width: 320px;
           // height: 320px;
        }
        /*<!--z-index: -10;-->*/
    }

</style>

<template>


  <!--        <div class="col-60">-->

  <div class=" col-60 menu d-flex flex-wrap" style="align-items: center;justify-content: space-between;">
    <div class="d-flex header" style="align-items: center;">

      <CheckIcon :m="activeCheck" style="width: 70px;"></CheckIcon>


      <div class="title" :class="bgcolor" v-if="activeCheck">
        <span v-if="activeCheck.acf.singleheader" v-html="activeCheck.acf.singleheader"></span>
        <span v-else v-html="activeCheck.title.rendered"></span>
      </div>

    </div>
    <div class="d-flex circles" v-if="activeCheck">


      <!--                <div  v-if="index===1"></div>-->


      <CircleIcon v-for="(c,index) in checks" :key="c.id" :m="c" v-if="c.slug"
                  v-on:click.native="goTo(c.slug)" :active="activeCheck.slug===c.slug"
                   :color="$route.meta.bgcolor"
      ></CircleIcon>


    </div>
    <!--            </div>-->
  </div>


</template>

<script>
import CheckIcon from './Icon.vue'
import store from '../../store'
import CircleIcon from './CircleIcon.vue'

export default {
  name: "Menu",
  props: ['activeCheck'],
  components: {
    CheckIcon, CircleIcon
  },
  computed: {
    checks() {

      if (this.$route.meta.view === 'speisenplaene') return store.state.menus
      else if (this.$route.meta.view === 'befragungen') return store.state.befragungen
      else if (this.$route.meta.view === 'verpflegungsausschuss') return store.state.vausschuss
      else return store.state.messungen

    },
    bgcolor() {
      return this.$route.meta.bgcolor
    },

  },
  methods: {
    goTo(slug) {
      if (this.$route.meta.view === 'messungen') {

        this.$router.push({name: "messung", params: {slug: slug}})
      } else if (this.$route.meta.view === 'speisenplaene') {
        this.$router.push({name: "speiseplan", params: {slug: slug}})
      } else if (this.$route.meta.view === 'befragungen') {
        this.$router.push({name: "befragung", params: {slug: slug}})
      } else if (this.$route.meta.view === 'verpflegungsausschuss') {
        this.$router.push({name: "verpflegungsausschuss", params: {slug: slug}})
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.menu {
  background-color: #fff;
  border-radius: 60px;

  margin-top: 50px;
  box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.1);
  @include mobile {
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    justify-content: center !important;
  }

  padding: 5px 10px;
  /*height: 50px;*/
  .header {
    @include mobile {
      width: 100%;
      justify-content:flex-start!important;
    }
  }

}

.circles {
  @include mobile {
    justify-content: center;
    display: flex;
    padding-left: 10px;
  }
}

.title {
  margin-left: 20px;
  font-size: 24px;
  font-weight: 600;
  @include mobile {
    font-size: 18px;
    margin-left: 0;
  }

  &.gruen {
    color: $gruen;
  }

  &.orange {
    color: $orange;
  }

  &.violett {
    color: $violett;
  }

  &.blau {
    color: $blau;
  }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Core from '../views/Core.vue'

Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      name: 'home',
      component: Core,
      meta: {'view': 'home', 'level' : 0, 'bgcolor':'blau', 'title': 'Start'}
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: Core,
        meta: {'view': 'feedback', 'level' : 1, 'bgcolor':'blau', 'title': 'Feedback'}
    },
    {
        path: '/messungen',
        name: 'messungen',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Core,
        meta: {'view': 'messungen', 'level': 1, 'bgcolor':'orange', 'title': 'Messungen'}
    },
    {
        path: '/messungen/:slug',
        name: 'messung',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Core,
        meta: {'view': 'messungen', 'level': 2, 'bgcolor':'orange', 'title': 'Messungen'}
    },
    {
        path: '/speisenplanchecks',
        name: 'speiseplaene',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Core,
        meta: {'view': 'speisenplaene', 'level': 1, 'bgcolor':'gruen', 'title': 'Speisenplanchecks'}
    },
    {
        path: '/speisenplanchecks/:slug',
        name: 'speiseplan',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Core,
        meta: {'view': 'speisenplaene', 'level': 2, 'bgcolor':'gruen', 'title': 'Speisenplanchecks'}
    },
    {
        path: '/befragungen',
        name: 'befragungen',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Core,
        meta: {'view': 'befragungen', 'level': 1, 'bgcolor':'violett', 'title': 'Befragungen'}
    },
    {
        path: '/befragungen/:slug',
        name: 'befragung',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Core,
        meta: {'view': 'befragungen', 'level': 2, 'bgcolor':'violett', 'title': 'Befragungen'}
    }, {
        path: '/verpflegungsausschuss',
        name: 'verpflegungsausschuesse',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Core,
        meta: {'view': 'verpflegungsausschuss', 'level': 1, 'bgcolor':'blau','title': 'Verpflegungsausschuss'}
    },
    {
        path: '/verpflegungsausschuss/:slug',
        name: 'verpflegungsausschuss',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Core,
        meta: {'view': 'verpflegungsausschuss', 'level': 2, 'bgcolor':'blau', 'title': 'Verpflegungsausschuss'}
    },
    {

        path: '/:page',
        name: 'page',
        component: Core,
        meta: {'view': 'page', 'level': 1, 'bgcolor':'orange'}
    },
    {

        path: '/verpflegung',
        name: 'verpflegung',
        component: Core,
        meta: {'view': 'home', 'level': 1}
    }
]

const router = new VueRouter({
    mode: 'history',
    base: '',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

export default router

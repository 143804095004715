<template>
  <div>
    <div class="row" style="padding-top: 50px;justify-content: space-between">

      <div class="col-md-22 col-60 handlung">
        <h4 class="d-block d-md-none" style="margin-top: 20px;">Handlungsempfehlungen für <br> Träger von Kitas und
          Schulen</h4>
        <!--        <div class="row">-->
        <!--          <div class="col-60">-->
        <a href="/dateien/2020/11/Handlungsempfehlungen.pdf" target="_blank"
           style="width: 100%;transform: translate3d(-10px,-10px,0)">

          <img :src="require('@/assets/img/Handlungsempfehlungen.png')" alt="" style="width: 100%;">
        </a>
        <!--          </div>-->
        <!--          <div class="col-60">-->


        <!--          </div>-->
        <!--        </div>-->

        <!--        <h4 class="blau"> Handlungsempfehlungen für Träger von Kitas und Schulen</h4>-->
      </div>
      <div class="col-md-38 col-60 tabwrap">
        <h4 class="d-block d-md-none" style="margin-bottom: 20px;">Instrumente für <br> Kitas und Schulen</h4>
        <div class="d-flex justify-content-center">

          <div class="  d-flex   tab ">
            <router-link to="/verpflegungsausschuss" style="width: 100%;">

              <img :src="require('@/assets/img/Tablett_Verpflegungsausschuss.png')" alt="">
            </router-link>

          </div>
          <div class="  d-flex    tab ">
            <router-link to="/speisenplanchecks" style="width: 100%;">

              <img :src="require('@/assets/img/Tablett_Speisenplanchecks.png')" alt="">
            </router-link>
          </div>
        </div>

        <div class="d-flex tabrow">

          <div class="  d-flex  tab ">
            <router-link to="/befragungen" style="width: 100%;">
              <img :src="require('@/assets/img/Tablett_Befragungen.png')" alt="">
            </router-link>
          </div>
          <div class="  d-flex  tab ">
            <router-link to="/messungen" style="width: 100%;">
              <img :src="require('@/assets/img/Tablett_Messungen.png')" alt="">
            </router-link>
          </div>

        </div>
      </div>
      <div class="row d-none d-md-flex" style="transform: translateY(-15px)">
        <div class="col-22">

          <h4 class="blau">
            Handlungsempfehlungen für <br> Träger von Kitas und Schulen
          </h4>
        </div>
        <div class="col-38">
          <h4 class="blau">

            Instrumente für <br> Kitas und Schulen
          </h4>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import store from '@/store'

export default {
  store,
  name: 'Home',
  data() {
    return {}
  },
  created: function () {

  },
  methods: {},

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" type="text/scss" scoped>
.tabwrap {
  @include mobile {
    order: 0;
  }
}

.handlung {
  @include mobile {
    order: 1;
    a {
      img {
        width: 50% !important;
      }
    }
  }
}

.tab {
  //align-items: flex-start;
  width: 50%;
  @include mobile {
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  img {
    width: 100%;
  }
}

h4.blau {
  color: darken(#6E889D, 10%);


  font-size: 20px;
  font-weight: 700;
}
</style>

import store from './store'
import jQuery from 'jquery';
import Vue from 'vue';
import Velocity from "velocity-animate";
export let customFunctions = {
    store,
    methods: {
        enterMessung: function (el) {

            // el.velocity({opacity:1, transform:['translateX(1000px)','translateX: 100px']}, 600)
            el.velocity({
                opacity: [1, 0],
                // transform: ['translateX(0px)', 'translateX(1000px)'],
                // transform: ['rotateZ(0deg)', 'rotateZ(20deg)']
            }, 600)
            jQuery('.whitebox.content').each(function () {

                this.velocity({
                    opacity: [1, 0],
                    transform: ['translateX(0px)', 'translateX(200px)'],
                },{duration: 400})
            })
        },
        katproducts(kat) {
            let results = this.products.filter(function (p) {
                return p.produkt_cats.indexOf(kat) !== -1;
                // return true;
            }.bind(kat))
            return results.sort(function (a, b) {
                return a.menu_order - b.menu_order
            })

        },
        goTo(target) {
            let pos = this.getPosition(target).offset - 110

            // document.querySelector('#'+target).scrollIntoView({ behavior: 'smooth' });
            window.scroll({top:pos, behavior: 'smooth'})
            // this.$SmoothScroll(
            //     this.getPosition(target).offset - 110
            //     , 500
            // )

        },
        getPosition(id) {
            let el = document.getElementById(id)
            let top = 0
            if (el) {
                top = el.getBoundingClientRect().top;
            }
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            let height = 0
            if (jQuery(el).parent().length > 0) {
                height = jQuery(el).parent().css('height').replace('px', '')
            }
            return {'top': top, 'height': height, 'offset': scrollTop + top}
        },
        shortLink: function (link) {
            // eslint-disable-next-line
            return link.replace(/^.*\/\/[^\/]+/, '');
        },
        scrollToTop() {
            jQuery('html,body').animate({scrollTop: 0}, 500);
        },
        chunkArray(arr, chunk_size) {
            let index = 0;
            let chunks = [];
            if (arr) {
                for (index = 0; index < arr.length; index += chunk_size) {
                    let myChunk = arr.slice(index, index + chunk_size);
                    // Do something if you want with the group
                    chunks.push(myChunk);
                }
            }
            return chunks;
        },
        getBackground(img) {
            return "url('" + img + "')"
        },
        getFeatured(page, size) {
            if (page) {

                if (size !== '') {
                    return page['_embedded']['wp:featuredmedia'] ? page['_embedded']['wp:featuredmedia'][0].source_url : false;
                }
                else {
                    return page['_embedded']['wp:featuredmedia'] ? page['_embedded']['wp:featuredmedia'][0]['media_details']['sizes'][size].source_url : false
                }
            }

        },
        changeColor(el, binding, vnode) {
            if (!binding.value.except) {

                let distance = jQuery(el).offset().top
                let h = jQuery(el).height()
                let bottom = distance + h
                let scr = vnode.context.scrollPosition
                Vue.nextTick()
                    .then(function () {
                        // DOM updated
                        if (scr + 150 > distance && scr < bottom) {
                            vnode.context.$store.commit('changeColor', binding.value.color)
                        }
                        else {
                            // vnode.context.$store.commit('changeColor', orig)
                        }
                    })
            }
        }
    },
    directives: {
        squared: {
            inserted(el, binding) {
                let factor = binding.value !== undefined ? binding.value : 1;
                let width = jQuery(el).width();
                jQuery(el).css('height', width / factor)
            }
        },
        color: {
            inserted(el, binding, vnode) {
                vnode.context.changeColor(el, binding, vnode)
            },
            componentUpdated(el, binding, vnode) {
                vnode.context.changeColor(el, binding, vnode)
            }
        }
    },
    computed: {
        sliderheight() {
            if (store.state.page) {
                return store.state.page.acf ? store.state.page.acf.height : []
            }
            else return '100'
        }
    }
}

export let connectionCheck = {
    data() {
        return {
            offline: false
        }
    },
    created() {
        window.addEventListener('online', this.networkChange)
        window.addEventListener('offline', this.networkChange)
    },
    methods: {
        networkChange(e) {
            this.offline = e.type == "offline";
        }
    }
}

export let resizeHandler = {
    data() {
        return {
            scrHeight: 0,
            windowWidth: 0
        }
    },
    methods: {
        getSize: function () {
            this.scrHeight = window.innerHeight
            this.windowWidth = jQuery(window).width()
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.getSize);
    },
    created: function () {
        this.getSize()
        window.addEventListener('resize', this.getSize, {passive: true})
    },
}

export let scrollHandler = {
    data: function () {
        return {
            scrollPosition: 0,
        }
    },
    components: {},
    methods: {
        handleScroll: function () {
            window.requestAnimationFrame(function () {
                this.scrollPosition = window.scrollY;
            }.bind(this))
        },
        focusMessage: function () {
            jQuery('#message').focus()
        }
    },
    destroyed() {
        window.removeEventListener('DOMContentLoaded', this.handleScroll);
    },
    created: function () {
        window.addEventListener('scroll', this.handleScroll, {passive: true});
    },
    watch: {}
}